/**
 * @generated SignedSource<<d03ca1cbeac87045a83ec368f9246c06>>
 * @relayHash f2b29e6d2dcfda1f6b0313c2a10b43b8
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-misc/480.0.0-2024-09-16T15:03:46.448Z/homepageRootQuery

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LocalRecentHistoryListInputType = {
  itemId?: string | null;
  timestamp?: string | null;
};
export type homepageRootQuery$variables = {
  abTestList?: ReadonlyArray<string | null> | null;
  fetchInitialHomepage: boolean;
  hasUserId: boolean;
  homepageId: string;
  isMobile: boolean;
  localRecentHistoryList?: ReadonlyArray<LocalRecentHistoryListInputType | null> | null;
  recentActivityDesignVariant?: string | null;
  userId: string;
  userType: string;
};
export type homepageRootQuery$data = {
  readonly viewer: {
    readonly homepage: {
      readonly modules: ReadonlyArray<{
        readonly __id: string;
      } | null> | null;
      readonly " $fragmentSpreads": FragmentRefs<"getHomePageMetaContent_homepage">;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"HpSharedLayout_viewer">;
  };
};
export type homepageRootQuery = {
  response: homepageRootQuery$data;
  variables: homepageRootQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": ([]/*: any*/),
  "kind": "LocalArgument",
  "name": "abTestList"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fetchInitialHomepage"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasUserId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "homepageId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isMobile"
},
v5 = {
  "defaultValue": ([]/*: any*/),
  "kind": "LocalArgument",
  "name": "localRecentHistoryList"
},
v6 = {
  "defaultValue": "default",
  "kind": "LocalArgument",
  "name": "recentActivityDesignVariant"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userType"
},
v9 = [
  {
    "kind": "Variable",
    "name": "abTestList",
    "variableName": "abTestList"
  },
  {
    "kind": "Variable",
    "name": "rootId",
    "variableName": "homepageId"
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "HomepageMetadata",
  "kind": "LinkedField",
  "name": "metadata",
  "plural": false,
  "selections": [
    (v10/*: any*/),
    (v11/*: any*/)
  ],
  "storageKey": null
},
v13 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cmsDisplayTitle",
  "storageKey": null
},
v17 = [
  {
    "kind": "Variable",
    "name": "userType",
    "variableName": "userType"
  }
],
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "format",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cmsId",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isFullBleedImage",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageUrl",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "path",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "LinkData",
  "kind": "LinkedField",
  "name": "linkData",
  "plural": false,
  "selections": [
    (v22/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isLinkable",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageCopy",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cta",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "backgroundColor",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "concreteType": "HeroBannerSettingsType",
  "kind": "LinkedField",
  "name": "heroBannerSettings",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "textAlignment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eyeBrowText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "headerText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dekText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ctaStyle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentPosition",
      "storageKey": null
    },
    (v26/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bannerImageWidth",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageHeight",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageWidth",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mobileImageHeight",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mobileImageWidth",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mobileImageUrl",
  "storageKey": null
},
v33 = {
  "condition": "isMobile",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    (v30/*: any*/),
    (v31/*: any*/),
    (v32/*: any*/)
  ]
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "itemId",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contemporaryTrackingString",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "concreteType": "Product",
  "kind": "LinkedField",
  "name": "items",
  "plural": true,
  "selections": [
    (v19/*: any*/),
    (v23/*: any*/),
    (v10/*: any*/),
    (v11/*: any*/),
    (v25/*: any*/),
    (v21/*: any*/),
    (v28/*: any*/),
    (v29/*: any*/)
  ],
  "storageKey": null
},
v37 = [
  (v15/*: any*/),
  (v16/*: any*/)
],
v38 = {
  "kind": "Variable",
  "name": "userId",
  "variableName": "userId"
},
v39 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v40 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "browseUrl",
  "storageKey": null
},
v41 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "categoryCode",
  "storageKey": null
},
v42 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v43 = {
  "alias": null,
  "args": null,
  "concreteType": "Seller",
  "kind": "LinkedField",
  "name": "seller",
  "plural": false,
  "selections": [
    (v39/*: any*/),
    (v42/*: any*/)
  ],
  "storageKey": null
},
v44 = [
  {
    "kind": "Literal",
    "name": "limit",
    "value": 1
  }
],
v45 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "smallPath",
  "storageKey": null
},
v46 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "localizedPdpUrl",
  "storageKey": null
},
v47 = [
  (v21/*: any*/),
  (v29/*: any*/),
  (v28/*: any*/)
],
v48 = {
  "alias": null,
  "args": null,
  "concreteType": "CollectionSpotlightImageType",
  "kind": "LinkedField",
  "name": "image",
  "plural": false,
  "selections": (v47/*: any*/),
  "storageKey": null
},
v49 = {
  "alias": null,
  "args": null,
  "concreteType": "LinkData",
  "kind": "LinkedField",
  "name": "linkData",
  "plural": false,
  "selections": [
    (v22/*: any*/)
  ],
  "storageKey": null
},
v50 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v51 = [
  (v50/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  }
],
v52 = {
  "alias": null,
  "args": null,
  "concreteType": "ConvertedAmountList",
  "kind": "LinkedField",
  "name": "convertedAmountList",
  "plural": true,
  "selections": (v51/*: any*/),
  "storageKey": null
},
v53 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isMultiSku",
  "storageKey": null
},
v54 = {
  "kind": "Literal",
  "name": "page",
  "value": "searchAndBrowse"
},
v55 = [
  (v54/*: any*/)
],
v56 = {
  "alias": "originalDisplayPrice",
  "args": (v55/*: any*/),
  "concreteType": "ItemDisplayPriceType",
  "kind": "LinkedField",
  "name": "displayPrice",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ConvertedAmountList",
      "kind": "LinkedField",
      "name": "originalConvertedAmountList",
      "plural": true,
      "selections": (v51/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": "displayPrice(page:\"searchAndBrowse\")"
},
v57 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isAuctionEnabled",
  "storageKey": null
},
v58 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v59 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v60 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v61 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reserveMet",
  "storageKey": null
},
v62 = {
  "alias": null,
  "args": null,
  "concreteType": "ItemClassification",
  "kind": "LinkedField",
  "name": "classification",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creationDate",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v63 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v64 = {
  "alias": null,
  "args": null,
  "concreteType": "ItemCreator",
  "kind": "LinkedField",
  "name": "creators",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "attribution",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Creator",
      "kind": "LinkedField",
      "name": "creator",
      "plural": false,
      "selections": [
        (v63/*: any*/),
        (v42/*: any*/)
      ],
      "storageKey": null
    },
    (v42/*: any*/)
  ],
  "storageKey": null
},
v65 = {
  "alias": "productPhotos",
  "args": (v44/*: any*/),
  "concreteType": "ItemPhoto",
  "kind": "LinkedField",
  "name": "photos",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "placeholder",
      "storageKey": null
    },
    (v45/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "masterOrZoomPath",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ItemPhotoVersion",
      "kind": "LinkedField",
      "name": "versions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "webPath",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v42/*: any*/)
  ],
  "storageKey": "photos(limit:1)"
},
v66 = [
  {
    "kind": "Literal",
    "name": "formatType",
    "value": "MOBILE"
  }
],
v67 = {
  "alias": null,
  "args": null,
  "concreteType": "QuickViewDisplayType",
  "kind": "LinkedField",
  "name": "quickViewDisplay",
  "plural": false,
  "selections": [
    (v10/*: any*/),
    {
      "alias": "mobileTitle",
      "args": (v66/*: any*/),
      "kind": "ScalarField",
      "name": "title",
      "storageKey": "title(formatType:\"MOBILE\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "QuickViewParagraph",
      "kind": "LinkedField",
      "name": "paragraphs",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        {
          "alias": "mobileText",
          "args": (v66/*: any*/),
          "kind": "ScalarField",
          "name": "text",
          "storageKey": "text(formatType:\"MOBILE\")"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "QuickViewCreator",
      "kind": "LinkedField",
      "name": "creators",
      "plural": true,
      "selections": [
        (v63/*: any*/),
        (v23/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "QuickViewCategorySegments",
      "kind": "LinkedField",
      "name": "paragraphAttributeNames",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "period",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "origin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "style",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "periodPrefix",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v68 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "homepageRootQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "HpSharedLayout_viewer"
          },
          {
            "alias": null,
            "args": (v9/*: any*/),
            "concreteType": "Homepage",
            "kind": "LinkedField",
            "name": "homepage",
            "plural": false,
            "selections": [
              {
                "kind": "InlineDataFragmentSpread",
                "name": "getHomePageMetaContent_homepage",
                "selections": [
                  (v12/*: any*/)
                ],
                "args": null,
                "argumentDefinitions": []
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "modules",
                "plural": true,
                "selections": [
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v3/*: any*/),
      (v7/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v8/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "homepageRootQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v9/*: any*/),
            "concreteType": "Homepage",
            "kind": "LinkedField",
            "name": "homepage",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "modules",
                "plural": true,
                "selections": [
                  (v14/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v15/*: any*/)
                    ],
                    "type": "SkinnyBannerModule",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v15/*: any*/),
                      (v16/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "carouselSpeed",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": (v17/*: any*/),
                        "concreteType": "TopHeroBannerMessages",
                        "kind": "LinkedField",
                        "name": "messages",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TopHeroBannerItem",
                            "kind": "LinkedField",
                            "name": "items",
                            "plural": true,
                            "selections": [
                              (v18/*: any*/),
                              (v19/*: any*/),
                              (v20/*: any*/),
                              (v21/*: any*/),
                              (v23/*: any*/),
                              (v24/*: any*/),
                              (v25/*: any*/),
                              (v27/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "videoUrl",
                                "storageKey": null
                              },
                              {
                                "condition": "isMobile",
                                "kind": "Condition",
                                "passingValue": false,
                                "selections": [
                                  (v28/*: any*/),
                                  (v29/*: any*/)
                                ]
                              },
                              (v33/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "TopHeroBannerModule",
                    "abstractKey": null
                  },
                  {
                    "condition": "fetchInitialHomepage",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v15/*: any*/),
                          (v10/*: any*/),
                          (v16/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "viewMoreLink",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Product",
                            "kind": "LinkedField",
                            "name": "items",
                            "plural": true,
                            "selections": [
                              (v34/*: any*/),
                              (v19/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "link",
                                "storageKey": null
                              },
                              (v21/*: any*/),
                              (v10/*: any*/),
                              (v35/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "HeroModule",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v15/*: any*/),
                          (v10/*: any*/),
                          (v16/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Product",
                            "kind": "LinkedField",
                            "name": "items",
                            "plural": true,
                            "selections": [
                              (v19/*: any*/),
                              (v10/*: any*/),
                              (v21/*: any*/),
                              (v23/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "CollectionsModule",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v15/*: any*/),
                          (v16/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "FeaturedItem",
                            "kind": "LinkedField",
                            "name": "primaryItem",
                            "plural": false,
                            "selections": [
                              (v25/*: any*/),
                              (v21/*: any*/),
                              (v28/*: any*/),
                              (v29/*: any*/),
                              (v33/*: any*/),
                              (v10/*: any*/),
                              (v23/*: any*/),
                              (v26/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "FeaturedItem",
                            "kind": "LinkedField",
                            "name": "items",
                            "plural": true,
                            "selections": [
                              (v19/*: any*/),
                              (v25/*: any*/),
                              (v21/*: any*/),
                              (v10/*: any*/),
                              (v23/*: any*/),
                              (v26/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "textColor",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "FeaturedModule",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v15/*: any*/),
                          (v16/*: any*/),
                          {
                            "alias": null,
                            "args": (v17/*: any*/),
                            "concreteType": "EditorialItem",
                            "kind": "LinkedField",
                            "name": "items",
                            "plural": true,
                            "selections": [
                              (v19/*: any*/),
                              (v23/*: any*/),
                              (v10/*: any*/),
                              (v11/*: any*/),
                              (v25/*: any*/),
                              (v21/*: any*/),
                              (v29/*: any*/),
                              (v28/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "EditorialModule",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v15/*: any*/),
                          (v10/*: any*/),
                          (v16/*: any*/),
                          (v36/*: any*/)
                        ],
                        "type": "ShopByModule",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v15/*: any*/),
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "subtitle",
                            "storageKey": null
                          },
                          (v16/*: any*/),
                          (v36/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Location",
                            "kind": "LinkedField",
                            "name": "location",
                            "plural": false,
                            "selections": [
                              (v10/*: any*/),
                              (v11/*: any*/),
                              (v21/*: any*/),
                              (v28/*: any*/),
                              (v29/*: any*/),
                              (v26/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "LocationItem",
                                "kind": "LinkedField",
                                "name": "items",
                                "plural": true,
                                "selections": [
                                  (v19/*: any*/),
                                  (v10/*: any*/),
                                  (v23/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "LocationsModule",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v15/*: any*/),
                          (v10/*: any*/),
                          (v16/*: any*/)
                        ],
                        "type": "ArrivalsModule",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v37/*: any*/),
                        "type": "RecentlyViewedModule",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v15/*: any*/),
                          (v16/*: any*/),
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Variable",
                                "name": "localRecentHistoryList",
                                "variableName": "localRecentHistoryList"
                              },
                              {
                                "kind": "Variable",
                                "name": "recentActivityDesignVariant",
                                "variableName": "recentActivityDesignVariant"
                              },
                              (v38/*: any*/)
                            ],
                            "concreteType": "RecentActivityItemsWithMeta",
                            "kind": "LinkedField",
                            "name": "recentActivityItemsWithMeta",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "itemsPerPage",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "RecentActivityItem",
                                "kind": "LinkedField",
                                "name": "items",
                                "plural": true,
                                "selections": [
                                  (v15/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Item",
                                    "kind": "LinkedField",
                                    "name": "serviceItem",
                                    "plural": false,
                                    "selections": [
                                      (v39/*: any*/),
                                      (v35/*: any*/),
                                      (v10/*: any*/),
                                      (v40/*: any*/),
                                      (v41/*: any*/),
                                      (v43/*: any*/),
                                      {
                                        "alias": null,
                                        "args": (v44/*: any*/),
                                        "concreteType": "ItemPhoto",
                                        "kind": "LinkedField",
                                        "name": "photos",
                                        "plural": true,
                                        "selections": [
                                          (v45/*: any*/),
                                          (v42/*: any*/)
                                        ],
                                        "storageKey": "photos(limit:1)"
                                      },
                                      (v46/*: any*/),
                                      (v42/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "RecentActivityModule",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v37/*: any*/),
                        "type": "RecommendedItemsModule",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v15/*: any*/),
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "viewAllLink",
                            "storageKey": null
                          }
                        ],
                        "type": "AuctionItemsModule",
                        "abstractKey": null
                      }
                    ]
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "index",
                        "storageKey": null
                      },
                      (v15/*: any*/),
                      {
                        "alias": null,
                        "args": (v17/*: any*/),
                        "concreteType": "PromoModuleMessages",
                        "kind": "LinkedField",
                        "name": "messages",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PromoItem",
                            "kind": "LinkedField",
                            "name": "items",
                            "plural": true,
                            "selections": [
                              (v19/*: any*/),
                              (v23/*: any*/),
                              (v24/*: any*/),
                              {
                                "condition": "isMobile",
                                "kind": "Condition",
                                "passingValue": false,
                                "selections": [
                                  (v21/*: any*/),
                                  (v28/*: any*/),
                                  (v29/*: any*/)
                                ]
                              },
                              {
                                "condition": "isMobile",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  (v32/*: any*/),
                                  (v30/*: any*/),
                                  (v31/*: any*/)
                                ]
                              },
                              (v25/*: any*/),
                              (v27/*: any*/),
                              (v20/*: any*/),
                              (v18/*: any*/),
                              (v10/*: any*/),
                              (v11/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v16/*: any*/)
                    ],
                    "type": "PromoModule",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v15/*: any*/),
                      (v16/*: any*/),
                      {
                        "alias": null,
                        "args": (v17/*: any*/),
                        "concreteType": "CollectionSpotlightItemMessages",
                        "kind": "LinkedField",
                        "name": "messages",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          (v11/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isCollection",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "videoEmbedId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "linkText",
                            "storageKey": null
                          },
                          (v25/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CollectionSpotlightImageType",
                            "kind": "LinkedField",
                            "name": "collectionImage",
                            "plural": false,
                            "selections": (v47/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CollectionSpotlightImageType",
                            "kind": "LinkedField",
                            "name": "collectionPhoneImage",
                            "plural": false,
                            "selections": (v47/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CollectionSpotlightImageType",
                            "kind": "LinkedField",
                            "name": "profileImage",
                            "plural": false,
                            "selections": (v47/*: any*/),
                            "storageKey": null
                          },
                          (v48/*: any*/),
                          (v23/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CollectionSpotlightItem",
                            "kind": "LinkedField",
                            "name": "items",
                            "plural": true,
                            "selections": [
                              (v10/*: any*/),
                              (v34/*: any*/),
                              (v35/*: any*/),
                              (v23/*: any*/),
                              (v48/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "CollectionSpotlightModule",
                    "abstractKey": null
                  },
                  (v13/*: any*/)
                ],
                "storageKey": null
              },
              (v12/*: any*/),
              {
                "condition": "hasUserId",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 3
                      },
                      {
                        "kind": "Literal",
                        "name": "numItems",
                        "value": 3
                      },
                      (v38/*: any*/)
                    ],
                    "concreteType": "PersonalizationModuleConnection",
                    "kind": "LinkedField",
                    "name": "personalization",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PersonalizationModuleEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PersonalizationModule",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v49/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "categoryTitle",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "variant",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Item",
                                "kind": "LinkedField",
                                "name": "categoryMainItem",
                                "plural": false,
                                "selections": [
                                  (v39/*: any*/),
                                  (v35/*: any*/),
                                  (v43/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ItemPricing",
                                    "kind": "LinkedField",
                                    "name": "pricing",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "FinancialAmount",
                                        "kind": "LinkedField",
                                        "name": "retailPrice",
                                        "plural": false,
                                        "selections": [
                                          (v50/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  (v42/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Item",
                                "kind": "LinkedField",
                                "name": "categoryItems",
                                "plural": true,
                                "selections": [
                                  (v46/*: any*/),
                                  (v39/*: any*/),
                                  (v35/*: any*/),
                                  (v43/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ItemPricing",
                                    "kind": "LinkedField",
                                    "name": "pricing",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "FinancialAmount",
                                        "kind": "LinkedField",
                                        "name": "retailPrice",
                                        "plural": false,
                                        "selections": [
                                          (v50/*: any*/),
                                          (v52/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  (v53/*: any*/),
                                  (v10/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "vertical",
                                    "storageKey": null
                                  },
                                  (v56/*: any*/),
                                  (v57/*: any*/),
                                  {
                                    "alias": null,
                                    "args": (v55/*: any*/),
                                    "concreteType": "AuctionLot",
                                    "kind": "LinkedField",
                                    "name": "relevantAuctionLot",
                                    "plural": false,
                                    "selections": [
                                      (v58/*: any*/),
                                      (v59/*: any*/),
                                      (v60/*: any*/),
                                      (v42/*: any*/)
                                    ],
                                    "storageKey": "relevantAuctionLot(page:\"searchAndBrowse\")"
                                  },
                                  {
                                    "alias": null,
                                    "args": (v55/*: any*/),
                                    "concreteType": "AuctionSolution",
                                    "kind": "LinkedField",
                                    "name": "relevantAuctionSolution",
                                    "plural": false,
                                    "selections": [
                                      (v61/*: any*/),
                                      (v42/*: any*/)
                                    ],
                                    "storageKey": "relevantAuctionSolution(page:\"searchAndBrowse\")"
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ShipmentQuote",
                                    "kind": "LinkedField",
                                    "name": "shipmentQuotes",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "isComplimentary",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "isCalculated",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "FinancialAmount",
                                        "kind": "LinkedField",
                                        "name": "totalAmount",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ConvertedAmounts",
                                            "kind": "LinkedField",
                                            "name": "convertedAmounts",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "USD",
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ShipmentServiceMethod",
                                        "kind": "LinkedField",
                                        "name": "serviceMethod",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Region",
                                            "kind": "LinkedField",
                                            "name": "region",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "regionName",
                                                "storageKey": null
                                              },
                                              (v42/*: any*/)
                                            ],
                                            "storageKey": null
                                          },
                                          (v42/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      (v42/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v62/*: any*/),
                                  (v64/*: any*/),
                                  (v65/*: any*/),
                                  (v49/*: any*/),
                                  (v67/*: any*/),
                                  (v42/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          },
          (v42/*: any*/),
          (v14/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "pageSize",
                "value": 18
              },
              {
                "kind": "Literal",
                "name": "recentlyViewedIds",
                "value": []
              },
              {
                "kind": "Literal",
                "name": "returnAmount",
                "value": 15
              },
              {
                "kind": "Literal",
                "name": "userIds",
                "value": []
              }
            ],
            "concreteType": "Item",
            "kind": "LinkedField",
            "name": "recommendedItems",
            "plural": true,
            "selections": [
              (v39/*: any*/),
              (v35/*: any*/),
              (v10/*: any*/),
              (v40/*: any*/),
              (v41/*: any*/),
              (v43/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "pdpMetaItems",
                "kind": "LinkedField",
                "name": "pdpMeta",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "topQuery",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v46/*: any*/),
              (v62/*: any*/),
              (v64/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isArt",
                "storageKey": null
              },
              (v23/*: any*/),
              {
                "alias": null,
                "args": (v55/*: any*/),
                "concreteType": "AuctionSolution",
                "kind": "LinkedField",
                "name": "relevantAuctionSolution",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasReserve",
                    "storageKey": null
                  },
                  (v42/*: any*/),
                  (v61/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuctionBidsConnection",
                    "kind": "LinkedField",
                    "name": "bids",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalResults",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "relevantAuctionSolution(page:\"searchAndBrowse\")"
              },
              {
                "alias": null,
                "args": (v55/*: any*/),
                "concreteType": "AuctionLot",
                "kind": "LinkedField",
                "name": "relevantAuctionLot",
                "plural": false,
                "selections": [
                  (v58/*: any*/),
                  (v42/*: any*/),
                  (v59/*: any*/),
                  (v60/*: any*/)
                ],
                "storageKey": "relevantAuctionLot(page:\"searchAndBrowse\")"
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "isTrade",
                    "value": false
                  },
                  (v54/*: any*/)
                ],
                "concreteType": "ItemDisplayPriceType",
                "kind": "LinkedField",
                "name": "displayPrice",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "textType",
                    "storageKey": null
                  },
                  (v52/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "quantityDisplay",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "percentageOff",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "amountType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "showPriceLabel",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "showPriceVariability",
                    "storageKey": null
                  }
                ],
                "storageKey": "displayPrice(isTrade:false,page:\"searchAndBrowse\")"
              },
              (v57/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isStorefrontOnly",
                "storageKey": null
              },
              (v53/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isRingItem",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "VariableAttribute",
                "kind": "LinkedField",
                "name": "variableAttributes",
                "plural": true,
                "selections": [
                  (v39/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "VariableAttributeSortType",
                    "kind": "LinkedField",
                    "name": "variableAttributeData",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SwatchSort",
                        "kind": "LinkedField",
                        "name": "swatchSort",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Swatch",
                            "kind": "LinkedField",
                            "name": "swatch",
                            "plural": false,
                            "selections": [
                              (v39/*: any*/),
                              (v63/*: any*/),
                              (v21/*: any*/),
                              (v42/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v42/*: any*/)
                ],
                "storageKey": null
              },
              (v56/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ItemPricing",
                "kind": "LinkedField",
                "name": "pricing",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FinancialAmount",
                    "kind": "LinkedField",
                    "name": "retailPrice",
                    "plural": false,
                    "selections": [
                      (v52/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v67/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ItemMeasurement",
                "kind": "LinkedField",
                "name": "measurement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "addLabel",
                        "value": true
                      },
                      {
                        "kind": "Literal",
                        "name": "fields",
                        "value": [
                          "height",
                          "width",
                          "depth",
                          "diameter",
                          "length"
                        ]
                      },
                      {
                        "kind": "Literal",
                        "name": "separator",
                        "value": " "
                      }
                    ],
                    "concreteType": "ItemDisplayMeasurementType",
                    "kind": "LinkedField",
                    "name": "display",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "unit",
                        "storageKey": null
                      },
                      (v68/*: any*/)
                    ],
                    "storageKey": "display(addLabel:true,fields:[\"height\",\"width\",\"depth\",\"diameter\",\"length\"],separator:\" \")"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ItemSize",
                    "kind": "LinkedField",
                    "name": "size",
                    "plural": false,
                    "selections": [
                      (v68/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": "isInEurope",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "regionName",
                    "value": "Europe"
                  }
                ],
                "kind": "ScalarField",
                "name": "isInRegion",
                "storageKey": "isInRegion(regionName:\"Europe\")"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Address",
                "kind": "LinkedField",
                "name": "address",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayCountry",
                    "storageKey": null
                  },
                  (v42/*: any*/)
                ],
                "storageKey": null
              },
              (v65/*: any*/),
              (v42/*: any*/)
            ],
            "storageKey": "recommendedItems(pageSize:18,recentlyViewedIds:[],returnAmount:15,userIds:[])"
          },
          {
            "condition": "hasUserId",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  (v38/*: any*/)
                ],
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": "hasLoggedInWithApp",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "deviceType",
                        "value": "IOS_APP"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "hasLoggedInWithDeviceType",
                    "storageKey": "hasLoggedInWithDeviceType(deviceType:\"IOS_APP\")"
                  },
                  (v42/*: any*/)
                ],
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-misc/480.0.0-2024-09-16T15:03:46.448Z/homepageRootQuery",
    "metadata": {},
    "name": "homepageRootQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "0006decb48dab22ae2546d6d54ad83ce";

export default node;
